import React from "react"
import { graphql, Link } from "gatsby"
import _ from 'lodash'
import PageTransition from 'gatsby-plugin-page-transitions';
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/layout'
import "./../components/DynamicPage.css"




function Page404(props) {

    return (
        <div>
            404 Page
        </div>
    )
}

export default Page404;
